/*---------------------------------------
* Author    : Do-Yeung Rhee
* Company   : BoD GmbH
* Created   : 21.07.2016 by Do-Yeung Rhee
* Last edit : 09.01.2020 by Niko Schotte
* Version   : 1.1
----------------------------------------*/

window.onload = function(){
    const head = document.head || document.getElementsByTagName("head")[0];

    function loadScript(url, callback = () => {}) {
        let script = document.createElement("script");
        script.src = url;
        script.async = false;
        script.onload = function() {
            callback();
        }
        head.appendChild(script);
    }

    // Adding shopWidget CSS to head
    var link=document.createElement('link');
    link.href='https://www.bod.de/public/css/bod/shopWidget.min.css';
    link.rel='stylesheet';
    head.appendChild(link);

    loadScript("https://www.bod.de/public/js/vendor/Hyphenopoly-4.12.0/Hyphenopoly_shopwidget_config.js");
    loadScript("https://www.bod.de/public/js/vendor/Hyphenopoly-4.12.0/Hyphenopoly_Loader.js");

    var ajaxSuccessCounter = 0;
    var widgetcount = books.length;

    books.forEach(function(obj) {

        var objectID = obj.objID;
        var objID = obj.objID;
        var swKey = obj.swKey;
        var type = obj.type;
        var size = obj.size;
        var font = obj.font;
        var shadow = obj.shadow;
        var contour = obj.contour;
        var coverContour = obj.coverContour;
        var shadowBtn = obj.shadowBtn;
        var contourBtn = obj.contourBtn;
        var fontColor = obj.fontColor;
        var contourColor = obj.contourColor;
        var bgColor = obj.bgColor;
        var btnFontColor = obj.btnFontColor;
        var btnColor = obj.btnColor;
        var btnContourColor = obj.btnContourColor;
        var mandantShopUrl = obj.mandantShopUrl;
//        var mandant = obj.mandant;
        var lang = obj.lang;

        var btnText = {
            "de": "ZUM BoD BUCHSHOP",
            "ch": "ZUM BoD BUCHSHOP",
            "dk": "Til BoD bogshop",
            "fi": "BoD:n verkkokauppaan",
            "fr": "VERS LA LIBRAIRIE BoD",
            "se": "TILL BOD:S BOKSHOP",
            "no": "TIL BoD BOKSHOP",
            "es": "LIBRERÍA BoD",
            "ts": "ZUM SHOP"
        };

        var contentError = {
            "de": {
                "msg1": "Dieser Titel ist leider<br>nicht länger verfügbar.",
                "msg2": "Aber im BoD-Buchshop gibt es viele weitere spannende Titel zu entdecken!"
            },
            "ch": {
                "msg1": "Dieser Titel ist leider<br>nicht länger verfügbar.",
                "msg2": "Aber im BoD-Buchshop gibt es viele weitere spannende Titel zu entdecken!"
            },
            "dk": {
                "msg1": "Denne titel kan ikke fås længere.",
                "msg2": "Men der er mange andre spændende titler at gå på opdagelse i i BoD-shoppen!"
            },
            "fi": {
                "msg1": "Kirja ei valitettavasti ole enää saatavilla.",
                "msg2": "Mutta BoD:n verkkokaupasta löytyy myös paljon muita mielenkiintoisia teoksia!"
            },
            "fr": {
                "msg1": "Ce titre n'est malheureusement<br>plus disponible.",
                "msg2": "Découvrez d'autres livres qui pourraient vous intéresser !"
            },
            "se": {
                "msg1": "Denna titel finns tyvärr inte längre.",
                "msg2": "Men i BoD:s Bokshop finns det många andra spännande titlar att upptäcka!"
            },
            "no": {
                "msg1": "Dessverre er denne tittelen<br>ikke lenger tilgjengelig.",
                "msg2": "Men det er mange flere spennende titeler å finne i BoD Bokshop!"
            },
            "es": {
                "msg1": "Desgraciadamente, este título ya<br>no está disponible.",
                "msg2": "¡Pero en la librería de BoD hay muchos otros libros que descubrir!"
            }
        };

        var serverError = {
            "de": {
                "msg1": "Dieser Titel ist derzeit<br>leider nicht verfügbar.",
                "msg2": "Bitte versuchen Sie es<br>später noch einmal.",
                "btn": "TITEL DERZEIT NICHT VERFÜGBAR"
            },
            "ch": {
                "msg1": "Dieser Titel ist derzeit<br>leider nicht verfügbar.",
                "msg2": "Bitte versuchen Sie es<br>später noch einmal.",
                "btn": "TITEL DERZEIT NICHT VERFÜGBAR"
            },
            "dk": {
                "msg1": "Denne titel kan ikke<br>fås for tiden.",
                "msg2": "Forsøg igen senere.",
                "btn": "DENNE TITEL KAN IKKE BESTILLES I ØJEBLIKKET"
            },
            "fi": {
                "msg1": "Kirja ei ole tällä<br>hetkellä saatavilla.",
                "msg2": "Ole hyvä ja yritä<br>myöhemmin uudelleen.",
                "btn": "KIRJA EI OLE SAATAVILLA"
            },
            "fr": {
                "msg1": "Ce titre est actuellement<br>indisponible.",
                "msg2": "Veuillez réessayer<br>plus tard.",
                "btn": "TITRE ACTUELLEMENT INDISPONIBLE"
            },
            "se": {
                "msg1": "Denna titel finns tyvärr<br>inte för närvarande.",
                "msg2": "Var god försök<br>igen senare.",
                "btn": "DENNA TITEL FINNS INTE LÄNGRE TILLGÄNGLIG"
            },
            "no": {
                "msg1": "Dessverre er denne tittelen<br>for øyeblikket ikke tilgjengelig.",
                "msg2": "Vennligst prøv<br>igjen senere.",
                "btn": "TITTEL FOR ØYEBLIKKET IKKE TILGJENGELIG"
            },
            "es": {
                "msg1": "Desgraciadamente, este título<br>no está disponible momentáneamente.",
                "msg2": "Íntentalo de nuevo<br>más tarde.",
                "btn": "TÍTULO MOMENTÁNEAMENTE NO DISPONIBLE"
            }
        };

				var bookSampleLinkText = {
						"de": "Jetzt probelesen",
						"ch": "Jetzt probelesen",
						"dk": "Læs uddrag",
						"fi": "Lukunäyte",
						"fr": "Lire un extrait",
						"se": "Provläs nu",
						"no": "Prøveles nå",
						"es": "Muestra de lectura"
				};

				var descriptionTitle = {
						"de": "Beschreibung",
						"ch": "Beschreibungn",
						"dk": "Beskrivelse",
						"fi": "Kuvaus",
						"fr": "Description",
						"se": "Beskrivning",
						"no": "Beskrivelse",
						"es": "Descripción"
				};

        if(document.querySelector("#bodShopWidget_"+objectID+"_" + type) !== null){
					objectID = objectID + "_" + type;
				}

        var widgetContent = '<section id="shopWidget_'+objectID+'" class="shopWidget" data-objid="'+objectID+'">'+
            '<header>'+
            '<div style="width:106px; height:180px; margin: 10px;float: left;">'+
            '<img id="shopWidget-cover_'+objectID+'" src="https://www.bod.de/public/img/bod-cover-placeholder.jpg" style="width:106px;">'+
            '</div>'+
            '<h1 id="shopWidget-bookTitle_'+objectID+'" class="hyphenate" lang="'+lang+'"></h1>'+
            '<span id="shopWidget-author_'+objectID+'" class="authorName"></span><br/>'+
            '<span id="shopWidget-price_'+objectID+'" class="shopWidget-price"></span><span id="shopWidget-currency_'+objectID+'" class="shopWidget-currency"></span><span id="shopWidget-bookType_'+objectID+'"></span><br>'+
            '<a id="shopWidget-bookSample_'+objectID+'" href target="_blank"></a>'+
            '</header>'+
            '<div id="shopWidget-bookContent_'+objectID+'">'+
            '<h2 id="shopWidget-bookDescriptionTitle_'+objectID+'"></h2>'+
            '<p id="shopWidget-bookDescription_'+objectID+'" class="hyphenate" lang="'+lang+'"></p>'+
            '</div>'+
            '<footer>'+
            '<div>'+
            '<a id="shopWidget-shopLink_'+objectID+'" class="shopWidget-shopLink" href="'+mandantShopUrl+'" target="_blank">'+btnText[lang]+'</a>'+
            '</div>'+
            '</footer>'+
            '</section>'+
            '<section id="shopWidget2_'+objectID+'" class="shopWidget2">'+
            '<div>'+
            '<a id="shopWidget-shopLink2_'+objectID+'" class="shopWidget-shopLink" href="'+mandantShopUrl+'" target="_blank">'+btnText[lang]+'</a>'+
            '</div>'+
            '</section>'+
            '<span id="shopWidget-hiddenSpan_'+objectID+'" class="shopWidget-hiddenSpan"></span>';

				console.log("bodShopWidget_"+objectID)
        document.getElementById("bodShopWidget_"+objectID).innerHTML = widgetContent;

        var widget = document.querySelector("#shopWidget_"+objectID);
        var widget2 = document.querySelector("#shopWidget2_"+objectID);
        var bookDesc = document.querySelector("#shopWidget-bookContent_"+objectID);

        widget.style.width="300px";
        widget.style.textAlign="left";

        if(size==="large"){
            widget.style.display = "block";
            widget2.style.display = "none";
            widget.style.height="525px";
            bookDesc.style.display = "block";
        }else if(size==="small"){
            widget.style.display = "block";
            widget2.style.display = "none";
            widget.style.height="260px";
            bookDesc.style.display = "none";
        }else if(size==="button"){
            widget.style.display = "none";
            widget2.style.display = "block";
        }

        if(font==="nonSerif"){
            widget.style.fontFamily="Arial";
            widget2.style.fontFamily="Arial";
            document.querySelector("#shopWidget-bookTitle_"+objectID).style.fontSize="18px";
            document.querySelector("#shopWidget-price_"+objectID).style.fontFamily="Arial";
            document.querySelector("#shopWidget-price_"+objectID).style.fontSize="18px";
            document.querySelector("#shopWidget-hiddenSpan_"+objectID).style.fontFamily="Arial";
        }else if(font==="serif"){
            widget.style.fontFamily="Georgia";
            widget2.style.fontFamily="Georgia";
            document.querySelector("#shopWidget-bookTitle_"+objectID).style.fontSize="17px";
            document.querySelector("#shopWidget-price_"+objectID).style.fontFamily="serif";
            document.querySelector("#shopWidget-price_"+objectID).style.fontSize="20px";
            document.querySelector("#shopWidget-hiddenSpan_"+objectID).style.fontFamily="Georgia";
        }

        if(shadow){
            widget.className += " shadow";
        }else{
            removeClass("shadow", widget);
        }

        if(contour){
            widget.className += " contour";
        }else{
            removeClass("contour", widget);
        }

        if(coverContour){
            document.querySelector("#shopWidget-cover_"+objectID).className = "coverContour";
        }else{
            removeClass("coverContour", document.querySelector("#shopWidget-cover_"+objectID));
        }

        var widgetBtn1 = document.querySelector("#shopWidget-shopLink_"+objectID);
        var widgetBtn2 = document.querySelector("#shopWidget-shopLink2_"+objectID);

        if(shadowBtn){
            widgetBtn1.className += " shadowBtn";
            widgetBtn2.className += " shadowBtn";
        }else{
            removeClass("shadowBtn", widgetBtn1);
            removeClass("shadowBtn", widgetBtn2);
        }

        if(contourBtn){
            widgetBtn1.className += " contourBtn";
            widgetBtn2.className += " contourBtn";
        }else{
            removeClass("contourBtn", widgetBtn1);
            removeClass("contourBtn", widgetBtn2);
        }

        widget.style.color=fontColor;
        widget.style.borderColor=contourColor;
        widget.style.backgroundColor=bgColor;

        widgetBtn1.style.color=btnFontColor;
        widgetBtn2.style.color=btnFontColor;
        widgetBtn1.style.backgroundColor=btnColor;
        widgetBtn2.style.backgroundColor=btnColor;
        widgetBtn1.style.borderColor=btnContourColor;
        widgetBtn2.style.borderColor=btnContourColor;

        /* AJAX REQUESTS
        **********************************************************************/

        // Random number for preventing the caching problems
        random = "rand=" + Math.random();

        var urlParams = random +
            "&swKey="+swKey+
            "&type="+type


        var request = new XMLHttpRequest();

        request.open('GET', `https://api.bod.com/v1/books/${objID}/shop-widget?`+urlParams, true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

        request.onload = function() {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                var data = JSON.parse(request.responseText);

								var type = obj.type;
								var objectInfo = data;
								var currency;

								// fill placeholder
								document.getElementById("shopWidget-cover_"+objectID).src = objectInfo.coverURL+"/104/0/";
								document.getElementById("shopWidget-bookTitle_"+objectID).title = objectInfo.title;
								document.getElementById("shopWidget-bookSample_"+objectID).innerHTML = bookSampleLinkText[lang];
								document.getElementById("shopWidget-bookDescriptionTitle_"+objectID).innerHTML = descriptionTitle[lang];

								// shorten title to fit in h1 book-title frame
								var bT = objectInfo.title;
								if (bT.length > 35){
									bT = bT.slice(0, 33);
									bT = bT + "...";
								}
								document.getElementById("shopWidget-bookTitle_"+objectID).innerHTML = bT;

								document.getElementById("shopWidget-author_"+objectID).innerHTML = objectInfo.authors;
								document.getElementById("shopWidget-price_"+objectID).innerHTML = objectInfo.price.replace(".", ",");

								if(objectInfo.currencyISO==="EUR"){
									currency = "&euro;";
								}
								else if(objectInfo.currencyISO==="DKK"){
									currency = "kr.";
								}
								else if(objectInfo.currencyISO==="SEK" || objectInfo.currencyISO==="NOK"){
									currency = "kr";
								}
								else{
									currency = objectInfo.currencyISO;
								}

								document.getElementById("shopWidget-currency_"+objectID).innerHTML = currency;

								if(mandantShopUrl.indexOf("bod.de") > 0 || mandantShopUrl.indexOf("bod.ch") > 0 ){
									if(type==="print"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "Buch";
									}else if(type==="ebook"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "E-Book";
									}
								}else if(mandantShopUrl.indexOf("bod.dk") > 0 ){
									if(type==="print"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "Bog";
									}else if(type==="ebook"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "E-Bog";
									}
								}else if(mandantShopUrl.indexOf("bod.fi") > 0 ){
									if(type==="print"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "Kirja";
									}else if(type==="ebook"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "E-Kirja";
									}
								}else if(mandantShopUrl.indexOf("bod.fr") > 0 ){
									if(type==="print"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "Livre papier";
									}else if(type==="ebook"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "Livre ebook";
									}
								}else if(mandantShopUrl.indexOf("bod.se") > 0 ){
									if(type==="print"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "Bok";
									}else if(type==="ebook"){
										document.getElementById("shopWidget-bookType_"+objectID).innerHTML = "E-Bok";
									}
								}

								if(objectInfo.hasBooksample == "Y"){
									document.getElementById("shopWidget-bookSample_"+objectID).href = objectInfo.bookSampleURL;
								}else if(objectInfo.hasBooksample == "N" || objectInfo.hasBooksample == "" || objectInfo.hasBooksample == null){
									document.getElementById("shopWidget-bookSample_"+objectID).style.display = "none";
								}

								var desc = objectInfo.blurb;
								var span = document.getElementById("shopWidget-hiddenSpan_"+objectID);
								span.innerHTML = desc;
								span.style.display = "block";
								var descHeight = span.offsetHeight;
								span.style.display = "none";

								if(descHeight > 244){
									desc = desc.substr(0, 475);
									var n = desc.lastIndexOf(" ");
									desc = desc.substr(0, n) + " (...)";

									document.getElementById("shopWidget-bookDescription_"+objectID).innerHTML = desc;
								}else{
									document.getElementById("shopWidget-bookDescription_"+objectID).innerHTML = desc;
								}

								var shopLink = objectInfo.bookURL+"?utm_source=saleswidget&utm_medium=referral&utm_campaign=saleswidget_"+size;

								document.getElementById("shopWidget-shopLink_"+objectID).href = shopLink;
								document.getElementById("shopWidget-shopLink2_"+objectID).href = shopLink;

								ajaxSuccessCounter += 1;

            } else {
                var widgetContainer = document.querySelector("#bodShopWidget_"+objectID);

                widgetContainer.innerHTML = '<section id="shopWidgetError_'+objectID+'" class="shopWidgetError">'+
                    '<img src="https://www.bod.de/fileadmin/bod/build/images/bod-logo.png">'+
                    '<p class="p1">'+contentError[lang]['msg1']+'</p>'+
                    '<p>'+contentError[lang]['msg2']+'</p>'+
                    '<footer>'+
                    '<div>'+
                    '<a id="shopWidget-shopLink_'+objectID+'" class="shopWidget-shopLink" href="'+mandantShopUrl+'" target="_blank">'+btnText[lang]+'</a>'+
                    '</div>'+
                    '</footer>'+
                    '</section>'+
                    '<section id="shopWidgetErrorBtn_'+objectID+'" class="shopWidgetErrorBtn">'+
                    '<div>'+
                    '<a id="shopWidget-shopLink2_'+objectID+'" class="shopWidget-shopLink" href="'+mandantShopUrl+'" target="_blank">'+btnText[lang]+'</a>'+
                    '</div>'+
                    '</section>';

                widgetSize(objectID, size, bookDesc);
            }
        };

        request.onerror = function() {
            var widget = document.querySelector("#bodShopWidget_"+objectID);

            widget.innerHTML = '<section id="shopWidgetError_'+objectID+'" class="shopWidgetError">'+
                '<img src="https://www.bod.de/fileadmin/bod/build/images/bod-logo.png">'+
                '<p><b>'+serverError[lang]['msg1']+'</b></p>'+
                '<p>'+serverError[lang]['msg2']+'</p>'+
                '</section>'+
                '<section id="shopWidgetErrorBtn_'+objectID+'" class="shopWidgetErrorBtn">'+
                '<div>'+
                '<a id="shopWidget-shopLinkErr" class="shopWidget-shopLink" href="#">'+serverError[lang]['btn']+'</a>'+
                '</div>'+
                '</section>';

            widgetSize(objectID, size, bookDesc);
            document.querySelector("#shopWidgetError_"+objectID).style.height="220px";
        };

        request.send();

    });
};

/*                          HELPER FUNCTIONS
***********************************************************************/
function removeClass( classname, element ) {
    var cn = element.className;
    var rxp = new RegExp( "s?b"+classname+"b", "g" );
    cn = cn.replace( rxp, '' );
    element.className = cn;
}

function widgetSize(objectID, size, bookDesc){
    var widget = document.querySelector("#shopWidgetError_"+objectID);
    var widget2 = document.querySelector("#shopWidgetErrorBtn_"+objectID);

    widget.style.width="300px";

    if(size==="large"){
        widget.style.display = "block";
        widget2.style.display = "none";
        widget.style.height="260px";
        bookDesc.style.display = "block";
    }else if(size==="small"){
        widget.style.display = "block";
        widget2.style.display = "none";
        widget.style.height="260px";
        bookDesc.style.display = "none";
    }else if(size==="button"){
        widget.style.display = "none";
        widget2.style.display = "block";
    }
}
